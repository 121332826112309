import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kredit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was du wissen solltest, wenn du einen Kredit aufnehmen möchtest" showCalc={false} />
            <Article>
                <h2>Keine Angst vor Krediten: Hier erfährst du alles wichtige zum Thema</h2>
                <p>
                    Du möchtest deine eigene Wohnung oder ein Haus kaufen? Dann wirst du dich natürlich bereits mit dem
                    Thema Kredit beschäftigt haben. Wie du sicher schon festgestellt hast, gibt es dabei viel zu
                    beachten. Deshalb haben wir dir in diesem Artikel alle wichtigen Infos zum Thema Kredit in
                    Österreich zusammengefasst. Und wir geben dir am Ende auch Tipps für dein Beratungsgespräch.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Kredit?</h2>
                <p>
                    Unter einem Kredit versteht man ganz allgemein jenes Geld, das man sich ausleiht, um eine größere
                    Investition zu tätigen. Es gibt einen Kreditgeber und einen Kreditnehmer. Wenn du diesen Artikel
                    liest, dann bist du - richtig geraten - wahrscheinlich zweiteres, also der Kreditnehmer. Als solcher
                    bezahlst du dem Kreditgeber meist monatlich eine gewisse Rate. Und zwar solange, bis du die gesamte
                    Kreditsumme plus der Zinsen zurückgezahlt hast. Aber dazu später mehr. Sehen wir uns erstmal an, wie
                    die Sache mit den Krediten begonnen hat.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kredit – die kurze Geschichte einer langen Tradition</h2>
                <p>
                    Das Ausleihen von Geld oder Waren gegen Zinsen ist eine Erfindung, die es seit dem Beginn der
                    menschlichen Zivilisation gibt. Die ersten Kredite wurden bereits um 3000 vor unserer Zeitrechnung
                    in Mesopotamien ausgestellt. Auch im antiken Griechenland wurden Kreditverträge geschlossen und auch
                    schriftlich dokumentiert. Im Mittelalter hatte das Kreditwesen einen großen Entwicklungsschub:
                    während im frühen Mittelalter hauptsächlich Warenkredite vergeben wurden, wurden im 13. Jahrhundert
                    die ersten Großbanken gegründet. Das führte dazu, dass das Kreditwesen in Europa aufblühte.
                </p>
                <p>
                    Mit der Lockerung des kirchlichen Zinsverbots im 16. Jahrhundert spielten auch Privatkredite eine
                    größere Rolle. Als Absicherung diente den Kreditgebern meistens der Grundbesitz der jeweiligen
                    Personen. Durch die Aufzeichnung aller Eigentums- und Schuldverhältnisse in Grundbüchern seit dem
                    18. Jahrhundert, wurde die Vergabe von Hypothekenkrediten wesentlich erleichtert. Schließlich
                    entstanden ab der Mitte des 19. Jahrhunderts infolge der Industrialisierung die ersten modernen
                    Kreditbanken, in der Form, in der wir sie auch heute noch kennen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kredite heute</h2>
                <p>
                    Durch die Entwicklung des Internet wurden Kredite und Informationen dazu für viele Menschen
                    zugänglicher. Doch es ist auch schwieriger geworden, die unzähligen verfügbaren Informationen zu
                    überblicken. Miracl hat das Ziel durch professionelle Hilfe die Zugänglichkeit zu Eigentum durch
                    Kredite wesentlich zu erleichtern und noch mehr Menschen zugänglich zu machen.
                </p>
                <hr />

                <h2>Wie bekomme ich einen Kredit?</h2>
                <p>
                    Einen Kredit beantragst du meistens bei einer Bank. Du füllst einen Antrag aus und reichst ihn bei
                    der Bank deiner Wahl ein. Bevor er genehmigt wird, überprüft diese dann deine Kreditwürdigkeit.
                    Bevor du das Kreditangebot annimmst, solltest du mehrere unterschiedliche Angebote miteinander
                    vergleichen. Schließlich ist man an einen Kreditvertrag oft jahrelang gebunden und möchte das
                    günstigste Angebot auswählen. Das ist im Alleingang nicht ganz so leicht, weshalb es die Möglichkeit
                    einer Beratung durch Expert:innen gibt. Nachdem die Bank sich versichert hat, dass du in der Lage
                    bist, den Kredit zurückzuzahlen, beginnt der Prozess des Vertragsabschlusses. Dein Kreditantrag wird
                    angenommen, sobald du ein gutes Einkommen hast, schuldenfrei bist und Sicherheiten hast, die
                    hinterlegt sind (z.B. ein Auto, Kunstwerke, andere Immobilien, etc.).
                </p>
                <p>
                    Doch auch mit schlechterer{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    ist es möglich einen Kredit zu bekommen. Dabei helfen dir Finanzexperten und Finanzexpertinnen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was kostet ein Kredit?</h2>
                <p>
                    Was ein Kredit dich kostet, hängt von vielen Faktoren bei deiner Kreditauswahl ab. Wo hast du deinen
                    Kredit abgeschlossen? Welche Summe hast du dir geliehen? Wie hoch sind die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    ? Grundsätzlich setzt sich deine Rate aus einem Zins- und einem Tilgungsanteil zusammen. Die Tilgung
                    senkt kontinuierlich deinen restlichen Kreditbetrag – deine sogenannte Restschuld. Doch bei einem
                    Kredit fallen auch sämtliche Nebenkosten an, von denen du zu Beginn vielleicht noch gar nichts
                    weißt.
                </p>
                <hr />

                <h2>Welche Nebenkosten fallen bei einem Kredit an?</h2>
                <p>
                    Bei einem Kredit musst du mit vielen zusätzlichen Kosten und Spesen rechnen. Neben Zinsen,
                    Kontoführungsspesen und Bearbeitungsgebühren, kommen beispielsweise noch Kosten für die
                    Bonitätsprüfung, eine Pfand­rechts­ge­bühr und Ausgaben für Versicherungen hinzu, die häufig von der
                    Bank verlangt werden.
                </p>
                <hr />

                <h2>Worauf du achten solltest, bevor du einen Kredit beantragst</h2>
                <p>
                    Als erstes solltest du sichergehen, dass du dir einen Kredit monatlich überhaupt leisten kannst.
                    Vergiss dabei nicht, dass momentan niedrige variable{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    wieder steigen können sobald die Leitzinsen und Marktzinsen ansteigen. Außerdem solltest du wissen,
                    an welche Leitzinsen der vertragliche Sollzinssatz gebunden ist (z.B. Euribor, Euro-Zinsswap, etc.)
                    Hinzu kommt ein Aufschlag, auch Marge genannt, auf den vertraglich gesicherten Referenzzinssatz. Auf
                    die Höhe der Marge solltest du unbedingt achten. Bei Krediten mit variabler Verzinsung wird die
                    Marge als wichtigster Parameter zum Vergleich herangezogen. Die Marge ist verhandelbar, aber auch
                    abhängig von deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Finanzierungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>So kannst du Kredite miteinander vergleichen</h2>
                <p>
                    Rechtzeitig vor Abschluss des Vertrags ist die Bank dazu verpflichtet, dir ein Informationsformular
                    zur Verfügung stellen, in dem alle Kosten in Form des effektiven Jahreszinssatzes enthalten sind.
                    Dadurch kannst du verschiedene Kreditangebote miteinander vergleichen und dich dann für das beste
                    entscheiden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Tipps bei der Kreditauswahl</h2>
                <ul>
                    <li>
                        Verlange bereits im Erstgespräch ein Musteroffert, das im Verbraucherkreditgesetz vorgegeben
                        ist. Es ist zwar unverbindlich und Banken können davor eine Bonitätsprüfung verlangen, aber es
                        bietet rechtzeitig die Möglichkeit, dir einen Überblick darüber zu verschaffen, was dich
                        erwartet.
                    </li>
                    <li>
                        Bei Versicherungen, die die Bank beim Kreditvertrag verlangt, solltest du mehrere Angebote (z.B.
                        von Versicherungsmakler*innen) einholen und miteinander vergleichen
                    </li>
                    <li>
                        Wird die Finanzierung von mehreren Menschen getragen (z.B. eine:r zweite:n. Kreditnehmer:in, von
                        Bürgen, etc.) solltest du dich darüber informieren, unter welchen Voraussetzungen persönlich
                        haftende Personen wieder aus dem Kredit entlassen werden können.
                    </li>
                    <li>
                        Vor Unterzeichnung eines Vertrags hast du das Recht auf die Aushändigung eines kostenlosen
                        verbindlichen Kreditvertragsentwurfes, um die Entscheidung überlegt treffen zu können.
                    </li>
                </ul>
                <hr />

                <h2>Einen Kredit finden, ist schwer – Wir helfen dir!</h2>
                <p>
                    Es gibt unzähligen Dinge, die man bei einem Kredit wissen und im Kopf behalten sollte, die schwer zu
                    überblicken sind. Deshalb scheuen sich manche Menschen davor, einen Kredit aufzunehmen. Wir bei
                    miracl haben es uns zur Aufgabe gemacht, allen Menschen zu ihrem Eigenheim zu verhelfen. Wir wollen
                    das Chaos bei der Kreditsuche kleiner machen und den günstigsten Kredit für jeden und jede finden.
                    Dafür haben wir ein Team von Expert:innen aufgestellt, das dir dabei helfen soll, dich ganz auf dein
                    neues Zuhause zu konzentrieren, während wir uns um den besten Kredit für dich kümmern.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kredit"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kredit"}
                heading={"Was du wissen solltest, wenn du einen Kredit aufnehmen möchtest"}
            />
        </Layout>
    );
};

export default Kredit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kredit", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
